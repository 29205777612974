import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import ButtonDefault from "../styles/buttons";

import Container from "./styles";

export const Modal = ({ setShow, children, title }) => {
    const onClose = () => {
        setShow(false);
    };

    return (
        <Container>
            <main>
                <strong>{title}</strong>

                <div className="close" onClick={onClose}>
                    <VscClose size={30} />
                </div>
                <section> {children} </section>
            </main>
        </Container>
    );
};

export const ModalButton = ({ title, children, buttonContent }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {show ? (
                <Modal setShow={setShow} title={title}>
                    {children}
                </Modal>
            ) : (
                <ButtonDefault
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    {buttonContent}
                </ButtonDefault>
            )}
        </>
    );
};
export const ModalButtonSimple = ({ title, children, buttonContent, style }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {show ? (
                <Modal show={setShow} title={title}>
                    {children}
                </Modal>
            ) : (
                <button
                    className={`modalButtonSimple ${style}`}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    {buttonContent}
                </button>
            )}
        </>
    );
};
