import React from "react";
import { ModalButton } from "../../../../../../components/Modal";
import NewBudgetForm from "../../../../../cotacao/components/NewBudgetForm";

function ModalQuote({ process, onSuccess }) {
    return (
        <ModalButton title="Orçamentos" buttonContent="Criar novo orçamento">
            <NewBudgetForm onSuccess={onSuccess} process={process} />
        </ModalButton>
    );
}
export default ModalQuote;
